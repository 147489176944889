import { gql } from '@apollo/client'

export const FetchLimitNews = gql`
  query PublishedNews {
    res: publishedNews {
      hight {
        id
        image
        title
        updated
        updatedAt
        insertedAt
        link
        sourceTitle
      }
    }
  }
`
// TODO (first:3)
