import { navigate } from 'gatsby'
import React, { memo } from 'react'
import { RouteComponentProps, useLocation } from '@reach/router'
import { Header, Footer, PortfolioDetailFeaturedNews, PortfolioDetailNavigation } from '@components'
import { Link } from '@elements'
import { Helmet } from 'react-helmet'
import styles from '../index.module.scss'

import { useQuery } from '@apollo/client'
import { partners as graphqlPartners } from '@graphql'
import { Partners } from '@types'

interface DetailProps extends RouteComponentProps {}

const Detail: React.FC<DetailProps> = props => {
  const { data: { res: partners } = {}, loading: partnersLoading } = useQuery<{ res: Partners }>(
    graphqlPartners.FetchPartners,
    { variables: { type: 'PORTFOLIO' } }
  )

  const location = useLocation()

  const id = location.pathname.replace('/detail/', '')
  const partner = partners?.find(i => i.id === id)

  const navigationRoutes = partners?.map(i => i.id)
  const partnerTitle = '| ' + partner?.title || ''
  const testimonial = partner?.testimonial

  return (
    <section className={styles.detail}>
      <Helmet>
        <title>Palo Santo {partnerTitle}</title>
        <meta property="og:url" content="https://palosanto.vc/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Palo Santo" />
        <meta
          property="og:description"
          content="Leading psychedelic therapeutics investment fund dedicated to increasing the global supply of clinically effective and accessible healthcare solutions"
        />
        <meta property="og:image" content="/assets/images/social.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet>

      <Header type="light" />
      <div className={styles.container}>
        <Link className={styles.detailFull} to="/portfolio" scrollToAnchor={'#Portfolio'}>
          <svg width="10" height="15" viewBox="0 0 10 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 2L2 7.5L8 13" stroke="#FFC469" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          FULL PORTFOLIO
        </Link>
        {!partnersLoading ? (
          <div className={styles.detailContainer}>
            <PortfolioDetailNavigation current="atai" routes={navigationRoutes} />
            <div className={styles.detailLogo}>
              <img src={'https://palosanto.vc/' + partner?.colorLogo} />
            </div>
            <div className={styles.detailTitle}>{partner?.slogan}</div>
            <div className={styles.detailLine}>
              <div className={styles.detailLineInner}></div>
            </div>
            <div className={styles.detailDescription}>{partner?.description}</div>
            <div className={styles.detailRow}>
              {testimonial && (
                <div className={styles.detailQuote}>
                  <div className={styles.detailQuoteText}>
                    <div className={styles.detailQuoteIconOpen}>
                      <svg width="30" height="21" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M13.2003 -4.76837e-06C4.24031 3.84 -2.79969 8.32 1.12031 27.92H15.7603V13.28H11.5203C11.6003 9.84 13.6003 8.4 17.0403 6.16L13.2003 -4.76837e-06ZM33.1203 -4.76837e-06C24.1603 3.84 17.1203 8.32 21.0403 27.92H35.6803V13.28H31.4403C31.5203 9.84 33.5203 8.4 36.9603 6.16L33.1203 -4.76837e-06Z"
                          fill="#FFC469"
                        />
                      </svg>
                    </div>
                    {testimonial.quote}
                    <div className={styles.detailQuoteIconClose}>
                      <svg width="30" height="22" viewBox="0 0 37 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M3.88031 28.88C12.8403 25.04 19.8803 20.56 15.9603 0.959997H1.32031V15.6H5.56031C5.48031 19.04 3.48031 20.48 0.0403126 22.72L3.88031 28.88ZM23.8003 28.88C32.7603 25.04 39.8003 20.56 35.8803 0.959997H21.2403V15.6H25.4803C25.4003 19.04 23.4003 20.48 19.9603 22.72L23.8003 28.88Z"
                          fill="#FFC469"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className={styles.detailQuoteAuthor}>
                    <span>{testimonial.name}</span> | {testimonial.position}
                  </div>
                </div>
              )}
              <div className={styles.detailButtons}>
                {testimonial && (
                  <div className={styles.detailPhoto}>
                    <img src={'https://palosanto.vc/' + testimonial.avatar} />
                  </div>
                )}
                {partner?.link && (
                  <div className={styles.detailRow}>
                    <a target="_blank" className={styles.detailButtonLeft} href={partner?.link}>
                      {partner?.link}
                    </a>
                    <Link
                      to={'/news#coverage'}
                      state={{ refPartner: partner.title }}
                      className={styles.detailButtonRight}
                    >
                      <>{partner?.linkLabel} In The News</>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.detailContainer}></div>
        )}
      </div>
      {testimonial && (
        <div className={styles.detailMobile}>
          <div className={styles.detailMobileQuote}>
            <div className={styles.detailMobileQuotePhoto}>
              <img src={'https://palosanto.vc/' + testimonial.avatar} />
            </div>
            <div className={styles.detailMobileQuoteAuthor}>
              <span>{testimonial.name}</span>
              {testimonial.position}
            </div>
            <div className={styles.detailMobileQuoteText}>“{testimonial.quote}”</div>
          </div>
          <div className={styles.detailMobileButtons}>
            {partner?.link && (
              <a target="_blank" className={styles.detailButtonLeft} href={partner?.link}>
                {partner?.link}
              </a>
            )}
            <div className={styles.detailButtonRight} onClick={() => navigate('/news#list')}>
              News
            </div>
          </div>
        </div>
      )}
      <div className={styles.container}>
        <div className={styles.detailMargin}>
          <PortfolioDetailFeaturedNews title="Featured News" amount={3} />
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default memo(Detail)
