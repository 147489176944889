import { gql } from '@apollo/client'

export const FetchVideos = gql`
  query Video {
    res: videos {
      id
      title
      link
      platform
      avatar
      insertedAt
      updatedAt
      description
    }
  }
`
