import { gql } from '@apollo/client'

export const FetchJournals = gql`
  query Journals {
    res: journals {
      id
      title
      rank
      link
      date
      abstract
      inserted_at
      updated_at
      source
    }
  }
`
