import { gql } from '@apollo/client'

export const FetchPartnerNews = gql`
  query FetchPartnerNews {
    res: partnerNews {
      author
      categories
      coverageFeaturedOrder
      date
      id
      image
      insertedAt
      link
      sourceTitle
      summary
      title
      partner {
        title
      }
      updated
      updatedAt
      withinOrder
    }
  }
`
