import { gql } from '@apollo/client'

export const FetchPodcasts = gql`
  query Podcasts {
    res: podcasts {
      id
      title
      link
      platform
      inserted_at
      updated_at
      avatar
    }
  }
`
