import { gql } from '@apollo/client'

export const FetchTestimonials = gql`
  query Testimonials {
    testimonials {
      avatar
      id
      name
      quote
      position
    }
  }
`
