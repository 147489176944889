import { gql } from '@apollo/client'

export const FetchAll = gql`
  query News($type: NewsType) {
    res: news(type: $type) {
      author
      categories
      date
      id
      image
      insertedAt
      link
      newsFeaturedOrder
      portfolioDetailOrder
      postId
      sourceTitle
      summary
      title
      topicId
      updated
      updatedAt
    }
  }
`
