import { gql } from '@apollo/client'

export const FetchPartners = gql`
  query Partners($type: PartnerType) {
    res: partners(type: $type) {
      colorLogo
      coverageOrder
      description
      greyLogo
      homePageOrder
      id
      insertedAt
      link
      linkLabel
      portfolioOrder
      slogan
      title
      updatedAt
      whiteLogo
      hoverImage
      testimonial {
        avatar
        id
        name
        quote
        position
      }
      news {
        author
        categories
        coverageFeaturedOrder
        id
        image
        date
        insertedAt
        link
        sourceTitle
        summary
        title
        updated
        updatedAt
        withinOrder
      }
    }
  }
`
