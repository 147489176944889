import { gql } from '@apollo/client'

export const SendContact = gql`
  mutation SendContact($first_name: String!, $last_name: String!, $email: String!, $message: String!) {
    sendContact(first_name: $first_name, last_name: $last_name, email: $email, message: $message) {
      first_name
      last_name
      email
      message
    }
  }
`

export const SendSignEmail = gql`
  mutation SignEmail($email: String!) {
    signEmail(email: $email) {
      email
    }
  }
`
