import { gql } from '@apollo/client'

export const FetchBooks = gql`
  query Books {
    res: books {
      id
      title
      link
      platform
      author
      inserted_at
      updated_at
      avatar
    }
  }
`
