import { gql } from '@apollo/client'

export const FetchMembers = gql`
  query Member {
    res: members {
      id
      avatar
      name
      surname
      description
      extendedFamily
      email
      order
      website
      linkedin
      recent_works {
        id
        link
        name
      }
    }
  }
`
